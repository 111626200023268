/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_mobile_analytics_app_id": "b7c6115c19fd49fd8d18814ae43aebd4",
    "aws_mobile_analytics_app_region": "eu-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "b7c6115c19fd49fd8d18814ae43aebd4",
            "region": "eu-west-2"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://35sqbv2ey5fcxcnf3ncr4a7yzu.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-5kpkibo3oretnmbogdqvy4z3ra",
    "aws_cloud_logic_custom": [
        {
            "name": "verify",
            "endpoint": "https://32prlb1b28.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:223412ff-8825-4290-b481-e2194024f3fa",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_1gi6c3RoZ",
    "aws_user_pools_web_client_id": "11cro6paq6790akgjt7el6q6u2",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
